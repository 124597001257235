<template>
  <div class="app-content home">
    <swiper class="swiper app-content" 
    :options="swiperOption" 
    ref="homeBanner"
    @slideChange = "handlerSlideChange">
      <swiper-slide>
        <all-round></all-round>
      </swiper-slide>
      <swiper-slide>
        <choose-us></choose-us>
      </swiper-slide>
      <swiper-slide>
        <main-games></main-games>
      </swiper-slide>
      <swiper-slide>
        <company-time></company-time>
      </swiper-slide>
      <swiper-slide>
        <process></process>
      </swiper-slide>
      <swiper-slide>
        <about-us></about-us>
      </swiper-slide>
    </swiper>
    <img class="next animate__animated" 
    :class="(pageIndex === slidesLength - 1) ? 'animate__fadeInUp' : 'animate__fadeInDown'" 
    @click="handlerClickArrow" 
    :src = arrowImg>
  </div>
</template>
<script>
import allRound from './all-round'
import chooseUs from './choose-us'
import mainGames from './main-games'
import companyTime from './company-time'
import process from './process'
import aboutUs from './about-us'
import { slideAnimate, firstSlideAnimation } from './homeSlideAnimate'
export default {
  name: "home",
  data() {
    return {
      swiperOption: {
        direction: "vertical"
      },
      pageIndex: 0, // 当前索引
      slidesLength: 0  // slide总数
    };
  },
  mounted() {
    this.slidesLength = this.$refs.homeBanner.$swiper.slides.length;
    // 第一屏动画
    firstSlideAnimation()
  },
  computed: {
    // 箭头背景
    arrowImg() {
      return this.pageIndex < this.slidesLength - 1 ? 
      '//txwl.internetbaidu.com/mobileTXW_3.0/chess/next.png' :
      '//txwl.internetbaidu.com/mobileTXW_3.0/chess/up.png'
    }
  },
  methods: {
    // 点击箭头
    handlerClickArrow() {
      if (this.pageIndex < this.slidesLength - 1 ) {
        this.$refs.homeBanner.$swiper.slideNext();
      } else {
        this.$refs.homeBanner.$swiper.slideTo(0);
      }
    },
    // slide 切换事件
    handlerSlideChange() {
        this.pageIndex = this.$refs.homeBanner.$swiper.realIndex;
        slideAnimate(this.pageIndex);
      },
  },
  components: {
    allRound,
    chooseUs,
    mainGames,
    companyTime,
    process,
    aboutUs
  }
}
</script>
<style lang="less">
.home {
  .swiper-slide {
    overflow: hidden;
  }
  .next {
    width: 150px;
    height: 90px;
    padding: 20px 50px;
    position: fixed;
    z-index: 99;
    left: 50%;
    margin-left: -75px;
    bottom: 0;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
}
</style>