<template>
      <div class="app-content about-us">
        <div class="main-warp">
          <h2 class="head-title">ABOUT US <br/>关于我们</h2>
          <p class="sub-text">
            我司秉承诚信、专业、勤奋、责任的企业文化，<br/>
            坚持技术创新、稳健务实。<br/>
            300+技术开发工程师致力于产品创新与提升，<br/>
            协助客户从品牌价值出发，<br/>
            为客户提供高效、稳定的包网系统，<br/>
            极力打造全球知名品牌博娱产品。
          </p>
          <div class="contact-main">
            <div class="contact-title">联系我们</div>
            <ul>

               <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/telegram.png)"></i>
                <a href="https://t.me/TX_GROUP998">@TX_GROUP998</a>
              </li>
              <!-- <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/telegram.png)"></i>
                <a href="https://t.me/TX_GROUP">@TX_GROUP</a>
              </li> -->
               <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/line.png)"></i>
                <a href="javascript:void(0);">@TXKJ18899</a>
              </li>
<!--               <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/phoe.png)"></i>
                <a href="tel:+63-998-167-6789">+63 998 167 6789</a>
              </li>-->
          <!--     <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/telegram.png)"></i>
                <a href="https://t.me/txwlgfzh">+63 921 950 9999</a>
              </li>
              <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/line.png)"></i>
                <a href="javascript:void(0);">+63 921 950 9999</a>
              </li>
              <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/photo.png)"></i>
                <a href="https://pt.im/txwlgfzh">+63 921 950 9999</a>
              </li>
              <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/qq.png)"></i>
                <a href="https://wpa.qq.com/msgrd?v=3&uin=632299999&site=qq&menu=yes">632299999 / 202199999</a>
              </li> -->
              <li>
                <i style="backgroundImage: url(//txwl.internetbaidu.com/mobileTXW_3.0/home/email.png)"></i>
                <a href="mailto:txkj8899@gmail.com">txkj8899@gmail.com</a>
              </li>
            </ul>
          </div>
          <footer-copy :pos="'absolute'"></footer-copy>
        </div>

    </div>
</template>
<script>
export default {
  name: "aboutUs",
  data () {
    return { };
  }
}
</script>
<style lang="less" scoped>
.about-us {
    background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/bg6.jpg') no-repeat center center;
    background-size: cover;
    .head-title {
      font-size: 90px;
      font-weight: bold;
      text-align: center;
      padding-top: 40px;
    }
    .main-warp {
        height: calc(100% - 100px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-shrink: 0;
        .sub-text {
          font-size: 36px;
          color: #7682A2;
          line-height: 50px;
          text-align: center;
          margin: 20px 0;
        }
        .contact-main {
          padding-bottom: 100px;
          .contact-title {
            font-size: 66px;
            font-weight: bold;
            width: 280px;
            text-align: center;
            margin: 0 auto;
            position: relative;
            margin-bottom: 60px;
            &:before, &:after {
              position: absolute;
              content: '';
              width: 200px;
              height: 1px;
              background: #fff;
              opacity: 0.2;
              top: 46%;
            }
            &:before {
              right: 300px;
            }
            &:after {
              left: 300px;
            }
          }
          ul {
            li {
              margin-bottom: 50px;
              display: flex;
              align-items: center;
              &:last-child {
                margin-bottom: 0;
              }
              i {
                width: 50px;
                height: 46px;
                background-size: auto 100%;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 30px;
              }
              a {
                font-size: 45px;
                color: #fff;
              }
            }
          }
        }
    }
}
</style>
