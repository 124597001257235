import vm from '@/main.js'

const firstSlideAnimation = () => {
    vm.utils.animateCSS( ".all-round .tx-logo", ["animate__backInLeft"]);
    vm.utils.animateCSS( ".all-round .phone", ["animate__rotateIn", "animate__delay-decimal4s"]);
    vm.utils.animateCSS( ".all-round .title", ["animate__rotateInDownLeft", "animate__delay-decimal4s"]);
    vm.utils.animateCSS( ".all-round .main-content", ["animate__backInRight", "animate__delay-decimal8s"]);
    vm.utils.animateCSS( ".all-round .video", ["animate__flipInY", "animate__delay-1s"]);
}

const slideAnimate = index => {
    if (index === 0) {
        firstSlideAnimation()
    } else if (index === 1) {
        vm.utils.animateCSS( ".choose-us .head-title", ["animate__rotateInDownLeft"]);
        vm.utils.animateCSS( ".choose-us .sub-title", ["animate__flipInY", "animate__delay-decimal2s"]);
        vm.utils.animateCSS( ".choose-us .sub-text", ["animate__backInRight", "animate__delay-decimal2s"]);
        vm.utils.animateCSS( ".choose-us .list-warp", ["animate__fadeInUpBig", "animate__duration-1s"]);
    } else if (index === 2) {
        vm.utils.animateCSS( ".main-games .head-title", ["animate__rotateInDownLeft"]);
        vm.utils.animateCSS( ".main-games .sub-text", ["animate__backInRight", "animate__delay-decimal2s"]);
        vm.utils.animateCSS( ".main-games .header-class", ["animate__bounceInLeft", "animate__delay-decimal8s"]);
        vm.utils.animateCSS( ".main-games .sub-logo", ["animate__fadeInUpBig", "animate__duration-1s", "animate__delay-decimal4s"]);
    } else if (index === 3) {
        vm.utils.animateCSS( ".company-time .head-title", ["animate__rotateInDownLeft"]);
        vm.utils.animateCSS( ".company-time .sub-text", ["animate__backInRight", "animate__delay-decimal2s"]);
        vm.utils.animateCSS( ".company-content ul", ["animate__backInUp"]);
        vm.utils.animateCSS( ".company-time .company0", ["animate__backInLeft", "animate__delay-decimal2s"]);
        vm.utils.animateCSS( ".company-time .company1", ["animate__backInRight", "animate__delay-decimal4s"]);
        vm.utils.animateCSS( ".company-time .company2", ["animate__backInLeft", "animate__delay-decimal6s"]);
        vm.utils.animateCSS( ".company-time .company3", ["animate__backInRight", "animate__delay-decimal8s"]);
        vm.utils.animateCSS( ".company-time .company4", ["animate__backInLeft", "animate__delay-1s"]);
        vm.utils.animateCSS( ".company-time .company5", ["animate__backInRight", "animate__delay-1-2s"]);
        vm.utils.animateCSS( ".company-time .company6", ["animate__backInLeft", "animate__delay-1-4s"]);
        vm.utils.animateCSS( ".company-time .company7", ["animate__backInRight", "animate__delay-1-6s"]);
    } else if (index === 4) {
        vm.utils.animateCSS( ".process .head-title", ["animate__rotateInDownLeft"]);
        vm.utils.animateCSS( ".process .sub-text", ["animate__backInLeft", "animate__delay-decimal2s"]);
        vm.utils.animateCSS( ".process .process-li0", ["animate__backInRight"]);
        vm.utils.animateCSS( ".process .process-li1", ["animate__backInRight", "animate__delay-decimal2s"]);
        vm.utils.animateCSS( ".process .process-li2", ["animate__backInRight", "animate__delay-decimal4s"]);
        vm.utils.animateCSS( ".process .process-li3", ["animate__backInRight", "animate__delay-decimal6s"]);
        vm.utils.animateCSS( ".process .process-li4", ["animate__backInRight", "animate__delay-decimal8s"]);
    } else if (index === 5) {
        vm.utils.animateCSS( ".about-us .head-title", ["animate__rotateInDownLeft"]);
        vm.utils.animateCSS( ".about-us .sub-text", ["animate__backInRight", "animate__delay-decimal2s"]);
        vm.utils.animateCSS( ".about-us .contact-main", ["animate__fadeInUpBig", "animate__duration-1s"]);
    }
}



export { slideAnimate, firstSlideAnimation }