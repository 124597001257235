<template>
    <div class="app-content choose-us">
        <div class="main-warp">
          <h2 class="head-title">为什么选择我们</h2>
          <ul class="sub-title">
            <li class="zztb">专注及<br/>品质追求</li><span></span>
            <li class="yjtb">严谨的<br/>项目流程</li><span></span>
            <li class="1000">优秀<br/>项目累计</li>
          </ul>
          <p class="sub-text">
            只要您需要，我们就能达到!<br/>
            200+资深研发工程师，用心打造优秀系统平台，<br/>
            持续优化迭代，您只需专心于运营，其他交给我们。             
          </p>
          <div class="list-warp">
            <ul class="choose-content">
              <li v-for="(item, key) in chooseList" :key = key :style="{backgroundImage: `url(${item.img})`}" @click="clickSlide(item)">
                <p>
                  {{item.text}}
                </p>
              </li>
            </ul>
            <div :class="['big-content', `content${bigContentPos}`]">
              <swiper ref="bigItem" class="swiper" style="width: 100%; height: 100%;" :options="swiperOption" v-if="onSwiper">
                <swiper-slide class="big-item" v-for="(item, key) in swiperList" :key = key>
                  <div class="title" :style="{backgroundImage: `url(${item.img})`}">
                    <p>{{item.text}}</p>
                  </div>
                  <div class="text">{{item.main}}</div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "chooseUs",
  data () {
    return {
      chooseList: [
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/Tjfy.png', 
          text: 'T级防御系统', 
          type: 0,
          main: '免费高速CDN，无限防御，均采用最新“企业级云技术”加密网络传输信息，最安全的硬件环境搭配。' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/jsmz.png', 
          text: '极速免转模式', 
          type: 0,
          main: '无需手动转账，自由携带，一键回收，避免一切繁琐操作。' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/dht.png', 
          text: '单后台多前端', 
          type: 1,
          main: '单后台同时营运多个前台，减少人力及成本开支。' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/cysapp.png', 
          text: '100%纯原生APP', 
          type: 0,
          main: '告别卡顿免维护，实时热更，极速游戏，全面升级游戏体验。' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/whzz.png', 
          text: '无痕转站技术', 
          type: 1,
          main: '老客户“隐形”过渡只需2小时,有效避免一般转站高流失率，无需重新注册立即开启游戏！' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/dlms.png', 
          text: '多种代理模式', 
          type: 1,
          main: '经典代理、全民代理、玩家无限裂变，最热门营销产生最大化利益！' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/mtjwh.png', 
          text: '全年免停机维护', 
          type: 2,
          main: '运营不停歇、独享服务器多备份，全年免停机维护！转化率比同类产品提高40%！' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/znyh.png', 
          text: '智能优惠系统', 
          type: 2,
          main: '积分商城、红包雨、大转盘、任务系统、自助升级系统、签到系统，提升用户活跃度必备神器。' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/dz.png', 
          text: 'vip定制平台', 
          type: 3,
          main: '自定义游戏替换排序，量身定制开发需求，设计专属平台品牌logo，API游戏任意选，真正做到您的平台您作主。' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/zfdj.png', 
          text: '永久免费支付对接', 
          type: 2,
          main: '快速入款、上千家市场热门支付实时更新，灵活配置入款渠道。' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/vip.png', 
          text: '专业vip售后服务', 
          type: 3,
          main: '从平台搭建到正式运营，专属客服和技术全方位指导，强大的技术客服售后团队 7*24 小时轮班制服务，坚强的后盾为您保驾护航。' 
        },
        { 
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/znfk.png', 
          text: '智能风控系统', 
          type: 3,
          main: '系统自动检测账户安全状态，洞悉业务异常，构建精准防御，保障平台与会员资金安全。' 
        },
      ],
      swiperOption: {
        speed: 300,
        autoplay: {
          delay: 3000
        }
      },
      bigContentPos: 0, // 详情swiper显示位置
      swiperList: [], // 当前轮播list
      timer: null,
      onSwiper: true // 控制轮播销毁、开启
    };
  },
  mounted() {
    this.swiperList = this.chooseList.filter( list => list.type === this.bigContentPos)
    this.autoChange()
  },
  methods: {
    autoChange() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.bigContentPos ++
        (this.bigContentPos >= this.swiperList.length + 1) && ( this.bigContentPos = 0 )
        this.swiperList = this.chooseList.filter( list => list.type === this.bigContentPos)
        // 先销毁，再实例化
        this.onSwiper = false;
        setTimeout(() => { this.onSwiper = true})
      }, 3000 * 3 + 300 * 2); //三个延迟时间 + 二个滚动时间
    },
    /**
     * 点击切换
     */
    clickSlide(item) {
      this.bigContentPos = item.type
      let swiperIndex = 0;
      let arr = this.chooseList.filter( list => list.type === this.bigContentPos)
      arr.forEach((list, index) => {
        if(list.text === item.text) {
          swiperIndex = index
        } 
      })
      arr.splice(swiperIndex, 1)
      this.swiperList = [item, ...arr]
      // 先销毁，再实例化
      this.onSwiper = false;
      setTimeout(() => { this.onSwiper = true})
      this.autoChange()
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
<style lang="less" scoped>
.choose-us {
    background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/bg2.jpg') no-repeat center center;
    background-size: cover;
    .head-title {
      font-size: 90px;
      font-weight: bold;
      text-align: center;
      padding: 20px 0;
    }
    .main-warp {
        height: calc(100% - 30px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-shrink: 0;
        .sub-title {
          width: 100%;
          height: 80px;
          display: flex;
          justify-content: center;
          span {
            height: 100%;
            width: 1px;
            background: #4a483f;
            margin: 0 30px;
          }
          li {
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 24px;
            line-height: 34px;
            color: #837F6F;
            background-repeat: no-repeat;
            background-position: left center;
            &:nth-child(1){
              padding-left: 90px;
              background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/zztb.png');
              background-size: 78px 79px;
            }
            &:nth-child(3) {
              padding-left: 140px;
              background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/yjtb.png');
              background-size: 130px 64px;
            }
            &:nth-child(5) {
              padding-left: 134px;
              background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/1000.png');
              background-size: 124px 43px;
            }
          }
        }
        .sub-text {
          font-size: 36px;
          color: #7682A2;
          line-height: 50px;
          text-align: center;
          margin: 30px 0;
        }
        .list-warp {
           height: 1080px;
           width: 96%;
           margin: 0 auto;
           position: relative;
          .choose-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            height: 100%;
            li {
              width: 32%;
              height: 23%;
              background-color: #191933;
              background-size: auto 100%;
              background-position: center center;
              margin: 0 2% 2% 0;
              &:nth-child(3n) {
                margin-right: 0;
              }
              p {
                font-size: 36px;
                font-weight: bold;
                text-align: center;
                padding-top: 52%;
              }
            }
          }
          .big-content {
            position: absolute;
            width: 66%;
            height: 48%;
            background: #1c1f4b url('//txwl.internetbaidu.com/mobileTXW_3.0/home/big-list.png');
            background-size: 100% 100%;
            &.content0 {
              top: 0;
              left: 0;
            }
            &.content1 {
              top: 0;
              left: 34%;
            }
            &.content2 {
              top: 50%;
              left: 0;
            }
            &.content3 {
              top: 50%;
              left: 34%;
            }
            .big-item {
              .title {
                width: 100%;
                height: 280px;
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: center center;
                margin: 0 auto;
                p {
                  font-size: 42px;
                  font-weight: bold;
                  padding-top: 200px;
                  text-align: center;
                }
              }
              .text {
                padding: 0 30px;
                font-size: 38px;
                line-height: 56px;
                text-align: justify;
              }
            }
          }
        }
    }
}
</style>