<template>
    <div class="app-content all-round">
        <div class="main-warp">
            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/home/logo.png" alt="" class="tx-logo">
            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/home/phone.png" alt="" class="phone">
            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/home/title.png" alt="" class="title">
            <p class="main-content">
                专业包网系统提供商、API游戏供应商<br/>
                综合包网+电竞包网+纯原生棋牌app包网<br/>
                +电子包网+彩票包网+体育包网，<br/>
                一流团队极致创造业界唯一！
            </p>
            <div class="video" @click="play = true"></div>
        </div>
        <div class="video-warp" v-if="play">
            <video ref="video" controls="controls" autoplay src="//txwl.internetbaidu.com/txws/new_images_one/zhzx/video/video_tx.mp4"></video>
            <img class="close" src="//txwl.internetbaidu.com/mobileTXW_3.0/index/close.png" alt="" @click="play = false;">
        </div>
    </div>
</template>
<script>
export default {
  name: "allRound",
  data () {
    return {
        play: false
    };
  }
}
</script>
<style lang="less" scoped>
.all-round {
    background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/bg1.jpg') no-repeat;
    background-position: center 36%;
    background-size: cover;
    .main-warp {
        height: calc(100% - 100px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-shrink: 0;
    }
    .tx-logo {
        width: 302px;
        height: 96px;
        margin: 10px 45px;
        align-self: start;
    }
    .phone {
        width: auto;
        height: 800px;
    }
    .title {
        display: block;
        width: auto;
        height: 246px;
    }
    .main-content {
        width: 770px;
        font-size: 42px;
        color: #EAEAEA;
        opacity: 0.68;
        line-height: 60px;
        text-align: center;
    }
    .video {
        width: 822px;
        height: 132px;
        background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/btn.png');
        background-size: 100% 100%;
    }
    .video-warp {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        .close {
            position: absolute;
            width: 56px;
            height: 56px;
            top: 0;
            right: 1%;
            margin-top: 41%;
        }
        video {
            width: 100%;
            margin-top: 40%;
        }
    }
}

.smallScreen() {
    .all-round {
        .phone {
            height: 600px;
        }
        .title {
            height: 200px;
        }
    }
}

// iPhone 6, 7, & 8
@media only screen and (max-device-width : 667px) and (-webkit-device-pixel-ratio : 2){ 
    .smallScreen()
}
// iphone 6+, 6s+, 7+, 8+
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3)  {
    .smallScreen()
}

</style>