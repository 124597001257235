<template>
    <div class="app-content main-games">
        <div class="main-warp">
          <h2 class="head-title">主流游戏 全面覆盖</h2>
          <p class="sub-text">
            专业视角，国际标准。我们以国际标准要求我们的产品，<br/>
            不断迭代，持续优化，已接入市面上主流的50+款游戏产品，<br/>
            保证最新最全的使用体验。
          </p>
          <div style="width: 100%">
            <div class="header-class">
              <swiper ref="gameSwiper" class="swiper" :options="swiperOption" @slideChange = "handlerSlideChange">
                <swiper-slide class="title-list" v-for="(item, key) in gamesList" :key = key  @click.native="changeList(key)">
                  {{item.class}}
                </swiper-slide>
              </swiper>
            </div>
            <div :class="['sub-logo', gamesList[currentList].encls]" :style="{backgroundImage: `url(${gamesList[currentList].bg})`}">
              <!-- logo个数大于6个  使用swiper展示 -->
              <div class="logo-swiper" v-if="gamesList[currentList].childenImg.length > 6">
                <swiper class="swiper logoSwiper" :options="logoSwiperOption">
                  <swiper-slide class="logo-slide" v-for="(item, key) in gamesList[currentList].childenImg" :key = key>
                    <img :src="item" alt="">
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
              </div>
              <!-- logo个数小于等于6个  正常展示 -->
              <ul class="logo-swiper" v-show="gamesList[currentList].childenImg.length <= 6">
               <li v-for="(item, key) in gamesList[currentList].childenImg" :key = key>
                 <img :src="item" alt="">
               </li>
              </ul>
            </div>

          </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "mainGames",
  data () {
    return {
      currentList: 2,
      gamesList: [
        {
          class: '棋牌类',
          encls: 'logo-chess',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/img-chess.png',
          childenImg: [
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/dt-logo.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/ky-logo.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/ds-logo.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/hlqo-logo.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/VG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/ly-logo.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/xsj-logo.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/fg-logo.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-chess/wlqp-logo.png',

          ]
        },
        {
          class: '视讯类',
          encls: 'logo-live',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/third_live.png',
          childenImg: [
           /*  '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/txh.png', */
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/OG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/WM.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/bg.png',
            // '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/HB.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/yx.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/AG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/BBIN.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/ob.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/kkw.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/abg.png',
          /*  '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-live/DS.png'*/
          ]
        },
        {
          class: '电子类',
          encls: 'logo-elec',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/bg.png',
          childenImg: [
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/MG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/PT.png',
           /* '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/ig.png',*/
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/PP.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/CQ9.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/youplay.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/sky.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/HABA.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/bbin.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/PS.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/JDB.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/XIN.png',
             '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/AG.png',
              '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/sea.png',
              '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/fg.png',
              '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/hc.png',
              '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-elec/pg.png',
          ]
        },
        {
          class: '体育类',
          encls: 'logo-sports',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-sports/bg.png',
          childenImg: [
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-sports/hg.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-sports/AG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-sports/sb.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-sports/IM.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-sports/sexy.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-sports/yx.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-sports/xj.png',
          ]
        },
        {
          class: '电竞类',
          encls: 'logo-gaming',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-gaming/bg.png',
          childenImg: [
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-gaming/LH.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-gaming/IM.png'
          ]
        },
        {
          class: '彩票类',
          encls: 'logo-color',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-color/bg.png',
          childenImg: [
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-color/IG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-color/VR.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-color/gy.png',
            // '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-color/BCB.png',
             '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-color/BGLottery.png',
              /*'//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-color/GG.png',*/
               '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-color/LHC.png'
          ]
        },
        {
          class: '捕鱼类',
          encls: 'logo-fish',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/fish.png',
          childenImg: [
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/AG.png',
           /* '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/GG.png',*/
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/CQ9.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/jdb.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/BG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/SG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/ly.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/vg.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/BBIN.png',
           /* '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/DB.png',*/
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/DT.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/FG.png',
            '//txwl.internetbaidu.com/mobileTXW_3.0/home/logo-fish/YL.png',
          ]
        }
      ],
      swiperOption: {
          // loop: true,
          initialSlide: 2,
          slidesPerView: 4,
          spaceBetween: 30,
          centeredSlides: true
      },
      logoSwiperOption: {
          slidesPerView: 'auto',
          slidesPerColumn: 2,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
      }
    };
  },
  methods: {
    changeList(val) {
      this.$refs.gameSwiper.$swiper.slideTo(val)
    },
    // slide 切换事件
    handlerSlideChange() {
        this.currentList = this.$refs.gameSwiper.$swiper.realIndex;
      },
  }
}
</script>
<style lang="less">
.main-games {
    background: #0d0b1f;
    .head-title {
      font-size: 90px;
      font-weight: bold;
      text-align: center;
      padding-top: 60px;
    }
    .main-warp {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        .sub-text {
          font-size: 36px;
          color: #7682A2;
          line-height: 50px;
          text-align: center;
          margin: 30px 0;
        }
        .header-class {
          width: 100%;
          height: 140px;
          background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/btns-bg.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &:after, &:before {
            position: absolute;
            content: '';
            width: 80px;
            height: 100%;
            background-size: 100% 100%;
            z-index: 5;
            top: 0;
          }
          &:before {
            background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/left-warp.png');
            left: 0;
          }
          &:after {
            background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/right-warp.png');
            right: 0;
          }
          .title-list {
            width: 208px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            color: #E4E4E1;
            border: 1px solid #E4E4E1;
            opacity: 0.4;
            border-radius: 40px;
            font-size: 36px;
            &.swiper-slide-active {
              background:linear-gradient(110deg,#E6006A 0%,#F83725 100%);
              opacity: 1;
              border: none;
            }
          }
        }
        .sub-logo {
          width: 100%;
          height: 1200px;
          background-repeat: no-repeat;
          background-position: center top;
          background-size: 100% auto;
          .logo-swiper {
            width: 96%;
            height: 500px;
            padding-top: 20px;
            margin: 0 auto;
            .logoSwiper {
              width: 100%;
              height: 100%;
              .logo-slide {
                width: 320px;
                height: 206px;
                img {
                  display: block;
                  width: 100%;
                  height: auto;
                }
              }
            }
            .swiper-pagination-bullets {
                bottom: 0;
                .swiper-pagination-bullet {
                    width: 30px;
                    height: 30px;
                    background: rgba(255, 255, 255, 0.8);
                &.swiper-pagination-bullet-active {
                    background: #B11D22;
                }
                }
            }
          }
          ul.logo-swiper {
              width: 96%;
              height: 500px;
              padding-top: 20px;
              margin: 0 auto;
              display: flex;
              flex-wrap: wrap;
              li {
                width: 33.3%;
                height: 206px;
                img {
                  display: block;
                  width: 100%;
                  height: auto;
                }
              }
            }
        }
    }
}

.smallScreen() {
    .main-games .main-warp {
      .sub-logo {
        height: 950px;
      }
    }
}

// iPhone 6, 7, & 8
@media only screen and (max-device-width : 667px) and (-webkit-device-pixel-ratio : 2){
    .smallScreen()
}
// iphone 6+, 6s+, 7+, 8+
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3)  {
    .smallScreen()
}
</style>
