<template>
        <div class="app-content process">
        <div class="main-warp">
          <h2 class="head-title">多种方案 积极响应</h2>
          <p class="sub-text">                           
            您的需求，就是我们的目标！<br/>
            专业客服24*7不间断服务，<br/>
            任何能帮助互赢的合理方案，我们都会积极配合。             
          </p>
          <ul class="process-content">
            <li v-for="(item, key) in processList" :key = key :style="{backgroundImage: `url(${item.bg})`}" :class="`process-li${key}`">
              <div class="title">
                <img :src="item.img" alt="">
                <h2>{{item.title}}</h2>
              </div>
              <p>{{item.text}}</p>
            </li>
          </ul>
        </div>
    </div>
</template>
<script>
export default {
  name: "process",
  data () {
    return {
      processList: [
        {
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/1(1).png',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/1.jpg',
          title: '初步沟通',
          text: '与天下网络客服或市场人员初步沟通您的需求，以及解答您的相关问题，达成初步合作意向；'
        },
        {
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/2(1).png',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/2.jpg',
          title: '签订协议',
          text: '费用与合作方案确认后，我们将为您发送相关协议并签订，同时启动开发前期准备'
        },
        {
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/3(1).png',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/3.jpg',
          title: '缴纳费用',
          text: '协议签订后，需缴纳协议中所明确的相关款项，如开办费，服务费等；'
        },
        {
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/4(1).png',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/4.jpg',
          title: '进入开发',
          text: '费用与合作方案确认后，我们将为您发送相关协议并签订，同时启动开发前期准备；'
        },
        {
          img: '//txwl.internetbaidu.com/mobileTXW_3.0/home/5(1).png',
          bg: '//txwl.internetbaidu.com/mobileTXW_3.0/home/5.jpg',
          title: '正式上线',
          text: '最终交付，正式运营，同时提供VIP客服，实时解答或反馈您的问题和需求；'
        }
      ]
    };
  }
}
</script>
<style lang="less" scoped>
.process {
    background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/bg5.jpg') no-repeat center center;
    background-size: cover;
    .head-title {
      font-size: 90px;
      font-weight: bold;
      text-align: center;
      padding-top: 40px;
    }
    .main-warp {
        height: calc(100% - 100px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-shrink: 0;
        .sub-text {
          font-size: 36px;
          color: #7682A2;
          line-height: 50px;
          text-align: center;
          margin: 20px 0;
        }
        .process-content {
          li {
            width: 1032px;
            height: 240px;
            background-size: 100% 100%;
            padding: 30px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 20px;
            .title {
              display: flex;
              align-items: flex-end;
              margin-bottom: 10px;
              img {
                width: auto;
                height: 66px;
                margin-right: 20px;
              }
              h2 {
                font-size: 46px;
                font-weight: bold;
                opacity: 8;
              }
            }
            p {
              font-size: 32px;
              color: #A0AAD1;
              line-height: 50px;
              width: 80%;
            }
          }
        }
    }
}

.smallScreen() {
    .process .main-warp {
      height: calc(100% - 50px);
        .process-content {
          li {
            height: 210px;  
          }
        }
    }
}

// iPhone 6, 7, & 8
@media only screen and (max-device-width : 667px) and (-webkit-device-pixel-ratio : 2){ 
    .smallScreen()
}
// iphone 6+, 6s+, 7+, 8+
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3)  {
    .smallScreen()
}
</style>