<template>
    <div class="app-content company-time">
        <div class="main-warp">
          <h2 class="head-title">前进脚步 从未停止</h2>
          <p class="sub-text">           
            我们拥有最专业的技术团队，<br/>
            并且积累了多年的行业经验和案例，为我们的合作保驾护航，<br/>
            共同发掘市场，实现价值。成功始于合作，合作共赢天下！             
          </p>
          <div class="company-content">
            <ul>
              <li class="company0"><h2>2016年7月</h2><p>天下网络包网系统上线</p></li>
              <li class="company1"><h2>2017年5月</h2><p>入驻平台突破<i>100家</i></p></li>
              <li class="company2"><h2>2018年3月</h2><p>新系统上线，双系统运行</p></li>
              <li class="company3"><h2>2018年10月</h2><p>入驻平台突破<i>300家</i></p></li>
              <li class="company4"><h2>2019年5月</h2><p>澳门G2E展会，隆重推出<br/>自主棋牌产品</p></li>
              <li class="company5"><h2>2019年7月</h2><p>马尼拉PAGE亚博会<br/>棋牌包网上线</p></li>
              <li class="company6"><h2 class="future">未来</h2><p>我们继续前进</p></li>
            </ul>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "companyTime",
  data () {
    return { 
    };
  }
}
</script>
<style lang="less" scoped>
.company-time {
    background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/bg4.jpg') no-repeat center center;
    background-size: cover;
    .head-title {
      font-size: 90px;
      font-weight: bold;
      text-align: center;
      padding-top: 60px;
    }
    .main-warp {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-shrink: 0;
        .sub-text {
          font-size: 36px;
          color: #7682A2;
          line-height: 50px;
          text-align: center;
          margin: 30px 0;
        }
        .company-content {
           width: 100%;
           height: 1360px;
           background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/img4.png') no-repeat center center;
           background-size: 100% auto;
          ul {
            width: 100%;
            height: 100%;
            background: url('//txwl.internetbaidu.com/mobileTXW_3.0/home/time.png') no-repeat center center;
            background-size: auto 100%;
            padding-top: 5%;
          }         
          li {
            font-size: 40px;
            line-height: 60px;
            opacity: 0.9;
            margin-bottom: 8px;
            &:nth-child(odd) {
              text-align: right;
              padding-right: 54%;
            }
            &:nth-child(even) {
              padding-left: 54%;
            }
            h2 {
              color: #DCC793;
              font-weight: bold;
              &.future {
                color: #586693;
              }
            }
            p {
              i {
                color: #DCC793;
              }
              &.red {
                 color: #F12042;
              }
            }
          }
        }
    }
}

.smallScreen() {
    .company-time .main-warp {
        .company-content {
          height: 1180px;
          ul {
            padding-top: 4%;
            li {
              margin-bottom: -8px;
            }
          }   
        }
    }
}

// iPhone 6, 7, & 8
@media only screen and (max-device-width : 667px) and (-webkit-device-pixel-ratio : 2){ 
    .smallScreen()
}
// iphone 6+, 6s+, 7+, 8+
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3)  {
    .smallScreen()
}
</style>